import axios from 'axios';
import { apiEndpoint } from '../../config';

const api = `${apiEndpoint}/meal-plan`;

export async function getMealPlan(body) {
  try {
    const response = await axios.post(api, body);
    return response.data;
  } catch (e) {
    throw e;
  }
}
