import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
    marginRight: 20,

    marginTop: 20,
  },
  label: {
    color: "gray",
    textAlign: "start",
    marginBottom: 5,
  },
}));

function LabelWrapper({ children, label, style = {} }) {
  const classes = useStyles();

  return (
    <div className={classes.container} style={style}>
      <Typography className={classes.label} variant="caption">
        {label}
      </Typography>
      {children}
    </div>
  );
}

export default LabelWrapper;
