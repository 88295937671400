import axios from 'axios';
import { apiEndpoint } from '../../config';

const api = `${apiEndpoint}/config`;

export async function getConfig() {
  try {
    const response = await axios.get(api);
    return response.data;
  } catch (e) {
    throw e;
  }
}
