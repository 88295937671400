import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
}));

function PageWrapper({ children, style = {} }) {
  const classes = useStyles();
  return (
    <div className={classes.container} style={style}>
      {children}
    </div>
  );
}

export default PageWrapper;
