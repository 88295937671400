import { useState } from "react";
import "./App.css";
import MealPlanConfig from "./components/MealPlanConfig";
import MealPlan from "./components/MealPlan";

const VIEWS = {
  CONFIG: "CONFIG",
  MEAL_PLAN: "MEAL_PLAN",
};

function App() {
  const [currentView, setCurrentView] = useState(VIEWS.CONFIG);
  const [mealPlan, setMealPlan] = useState(false);

  function handleMealPlan(plan) {
    setMealPlan(plan);
    setCurrentView(VIEWS.MEAL_PLAN);
  }

  function handleBack() {
    setCurrentView(VIEWS.CONFIG);
  }

  function updateMealPlan(plan) {
    setMealPlan(plan);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {currentView === VIEWS.CONFIG && (
        <MealPlanConfig handleMealPlan={handleMealPlan} />
      )}
      {currentView === VIEWS.MEAL_PLAN && (
        <MealPlan
          mealPlan={mealPlan}
          handleBack={handleBack}
          updateMealPlan={updateMealPlan}
        />
      )}
    </div>
  );
}

export default App;
