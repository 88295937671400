import React from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  dialogContent: {
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    fontWeight: 'bold',
  },
  divider: {
    marginBottom: 10,
    marginTop: 10,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
  },
}));

function RecipeDialog({
  name,
  handleClose,
  ingredients,
  calories,
  fat,
  protein,
  carbs,
}) {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);

  function handleTabChange(_, tab) {
    setCurrentTab(tab);
  }

  return (
    <Dialog open fullWidth onClose={handleClose}>
      <DialogTitle>{name}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Tabs
          TabIndicatorProps={{ style: { color: '#ffc4ce' } }}
          style={{
            marginTop: -20,
            marginBottom: 20,
            borderBottom: '1px solid lightgray',
          }}
          value={currentTab}
          onChange={handleTabChange}
        >
          <Tab label="Ingredients" color="pink" />
          <Tab label="Macros" color="pink" />
        </Tabs>
        {currentTab === 0 && (
          <React.Fragment>
            {ingredients.map(ingredient => (
              <Typography>{ingredient}</Typography>
            ))}
          </React.Fragment>
        )}
        {currentTab === 1 && (
          <React.Fragment>
            <div className={classes.row}>
              <div className={classes.column}>
                <Typography>{calories}</Typography>
                <Typography className={classes.caption} variant="caption">
                  Calories
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography>{carbs}g</Typography>
                <Typography className={classes.caption} variant="caption">
                  Carbs
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography>{protein}g</Typography>
                <Typography className={classes.caption} variant="caption">
                  Protein
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography>{fat}g</Typography>
                <Typography className={classes.caption} variant="caption">
                  Fat
                </Typography>
              </div>
            </div>
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default RecipeDialog;
