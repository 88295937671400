import React from "react";
import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import PageWrapper from "../PageWrapper";
import { useFormInput } from "../../hooks";
import LabelWrapper from "../LabelWrapper";
import { getConfig } from "../../services/ConfigService";
import { useEffect, useState } from "react";
import { getMealPlan } from "../../services/MealPlanService";

const useStyles = makeStyles(() => ({
  input: {
    borderRadius: 40,
    width: 110,
    backgroundColor: "white",
    border: "0px solid white",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  inputFull: {
    borderRadius: 40,
    backgroundColor: "white",
    border: "0px solid white",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  row: {
    display: "flex",
    marginTop: 20,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: "bold",
    color: "white",
    textDecoration: "underline",
  },
  getMealPlanButton: {
    marginTop: 40,
    borderRadius: 10,
    backgroundColor: "white",
    color: "gray",
  },
  inputProp: {
    color: "gray",
    fontWeight: "bold",
  },
  spinner: {
    color: "white",
    marginTop: 40,
  },
}));

function MealPlanConfig({ handleMealPlan }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const carbs = useFormInput();
  const protein = useFormInput();
  const calories = useFormInput();
  const fat = useFormInput();
  const calorieBuffer = useFormInput();
  const macroBuffer = useFormInput();

  const [mustContainSnack, setMustContainSnack] = useState(false);
  const [ingredientBlackList, setIngredientBlackList] = useState([]);
  const [ingredientDesired, setIngredientDesired] = useState([]);

  async function loadData() {
    try {
      const configResponse = await getConfig();
      carbs.setValue(Number((configResponse.carbsPerc * 100).toFixed(2)));
      protein.setValue(Number((configResponse.proteinPerc * 100).toFixed(2)));
      fat.setValue(Number((configResponse.fatPerc * 100).toFixed(2)));
      calories.setValue(Number(configResponse.calories));
      calorieBuffer.setValue(Number(configResponse.calorieBuffer));
      macroBuffer.setValue(Number(configResponse.macroBufferInCalories));

      setMustContainSnack(configResponse.mustContainSnack);
      setIngredientBlackList(configResponse.ingredientBlackList.join(","));
      setIngredientDesired(configResponse.ingredientDesired.join(","));
      setLoading(false);
      console.log("configRespopnse: ", configResponse);
    } catch (e) {
      console.log("ERROR getting config: ", e);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  function isNumber(input) {
    return !Number.isNaN(Number(input));
  }

  function isValidInput() {
    if (
      !carbs.value ||
      !protein.value ||
      !macroBuffer.value ||
      !calories.value ||
      !fat.value ||
      !calorieBuffer.value
    )
      return true;

    return !(
      isNumber(carbs.value) &&
      isNumber(protein.value) &&
      isNumber(macroBuffer.value) &&
      isNumber(calories.value) &&
      isNumber(fat.value) &&
      isNumber(calorieBuffer.value)
    );
  }

  async function onGetMealPlanClick() {
    try {
      setLoading(true);
      let ingredientDesiredFull = [];
      let ingredientBlackListFull = [];

      if (ingredientDesired) {
        ingredientDesiredFull = ingredientDesired.split(",");
      }

      if (ingredientBlackList) {
        ingredientBlackListFull = ingredientBlackList.split(",");
      }

      const body = {
        calories: calories.value,
        proteinPerc: Number((protein.value / 100).toFixed(4)),
        carbsPerc: Number((carbs.value / 100).toFixed(4)),
        fatPerc: Number((fat.value / 100).toFixed(4)),
        macroBufferInCalories: Number(macroBuffer.value),
        calorieBuffer: Number(calorieBuffer.value),
        ingredientBlackList: ingredientBlackListFull,
        ingredientDesired: ingredientDesiredFull,
        mustContainSnack,
      };
      const response = await getMealPlan(body);
      console.log("response: ", response);
      if (response === "ERROR") {
        alert("There weren't any meal plans that match this configuration");
        setLoading(false);
        return;
      }
      handleMealPlan(response);
      setLoading(false);
    } catch (e) {
      console.log("ERROR getting meal plan: ", e);
      setLoading(false);
    }
  }

  console.log("Ingredient BlackList: ", ingredientBlackList);
  return (
    <PageWrapper>
      <Typography className={classes.title} variant="h4">
        Meal Plan Options
      </Typography>
      {loading ? (
        <CircularProgress size={100} className={classes.spinner} />
      ) : (
        <React.Fragment>
          <div className={classes.row}>
            <LabelWrapper label="Calories">
              <TextField
                placeholder="Calories"
                {...calories}
                type="number"
                className={classes.input}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputProp,
                }}
              />
            </LabelWrapper>
            <LabelWrapper label="Protein %">
              <TextField
                placeholder="Protein %"
                {...protein}
                type="number"
                className={classes.input}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputProp,
                }}
              />
            </LabelWrapper>
            <LabelWrapper label="Carbs %">
              <TextField
                placeholder="Carbs %"
                {...carbs}
                type="number"
                className={classes.input}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputProp,
                }}
              />
            </LabelWrapper>
            <LabelWrapper label="Fat %">
              <TextField
                placeholder="Fat %"
                type="number"
                {...fat}
                className={classes.input}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputProp,
                }}
              />
            </LabelWrapper>
            <LabelWrapper label="Calorie Buffer">
              <TextField
                placeholder="Calorie Buffer"
                type="number"
                className={classes.input}
                {...calorieBuffer}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputProp,
                }}
              />
            </LabelWrapper>
            <LabelWrapper label="Macro Buffer in calories">
              <TextField
                placeholder="Macro Buffer"
                {...macroBuffer}
                type="number"
                className={classes.input}
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputProp,
                }}
              />
            </LabelWrapper>
            <LabelWrapper
              style={{ width: "100%", maxWidth: 300 }}
              label="Ingredient BlackList"
            >
              <TextField
                // placeholder="Ingredient BLacklist"
                value={ingredientBlackList}
                onChange={(e) => setIngredientBlackList(e.target.value)}
                className={classes.inputFull}
                // fullWidth
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputProp,
                }}
              />
            </LabelWrapper>
            <LabelWrapper
              style={{ width: "100%", maxWidth: 300 }}
              label="Ingredients Desired"
            >
              <TextField
                // placeholder="Ingredient BLacklist"
                value={ingredientDesired}
                onChange={(e) => setIngredientDesired(e.target.value)}
                className={classes.inputFull}
                // fullWidth
                InputProps={{
                  disableUnderline: true,
                  className: classes.inputProp,
                }}
              />
            </LabelWrapper>
          </div>
          <Button
            disableElevation
            variant="contained"
            className={classes.getMealPlanButton}
            disabled={isValidInput()}
            onClick={onGetMealPlanClick}
          >
            Get Meal Plan
          </Button>
        </React.Fragment>
      )}
    </PageWrapper>
  );
}

export default MealPlanConfig;
