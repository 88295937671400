import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Refresh, RemoveRedEye } from "@material-ui/icons";
import PageWrapper from "../PageWrapper";
import { getMealPlan } from "../../services/MealPlanService";
import RecipeDialog from "../RecipeDialog";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "bold",
    color: "white",
    textDecoration: "underline",
  },
  card: {
    width: "100%",
    marginTop: 20,
    overflow: "initial",
  },
  iconButton: {
    position: "absolute",
    top: 20,
    left: 20,
    color: "white",
  },
  caption: {
    fontWeight: "bold",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  divider: {
    marginBottom: 10,
    marginTop: 10,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginRight: 20,
  },
  getMealPlanButton: {
    backgroundColor: "white",
    color: "gray",
    position: "fixed",
    bottom: 20,
    right: 20,
  },
  spinnerContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  spinner: {
    color: "white",
  },
  cardContent: {
    position: "relative",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

function Recipe({ recipe, showView, onViewClick }) {
  const classes = useStyles();
  console.log("recipe: ", recipe);
  return (
    <React.Fragment>
      <div>
        <div className={classes.column}>
          <Typography variant="h6">{recipe.name} </Typography>
          {!!recipe.percentageReduction && (
            <span style={{ fontSize: 12, color: "indianred", marginLeft: 5 }}>
              {recipe.percentageReduction}% reduced
            </span>
          )}
          {recipe.mealType && (
            <Typography variant="caption">
              {recipe.mealType.toLowerCase()}
            </Typography>
          )}
        </div>
        <div
          className={classes.row}
          style={{ justifyContent: "space-between" }}
        >
          <div className={classes.row}>
            <div className={classes.column}>
              <Typography>{recipe.calories}</Typography>
              <Typography className={classes.caption} variant="caption">
                Calories
              </Typography>
            </div>
            <div className={classes.column}>
              <Typography>{recipe.carbs}g</Typography>
              <Typography className={classes.caption} variant="caption">
                Carbs
              </Typography>
            </div>
            <div className={classes.column}>
              <Typography>{recipe.protein}g</Typography>
              <Typography className={classes.caption} variant="caption">
                Protein
              </Typography>
            </div>
            <div className={classes.column}>
              <Typography>{recipe.fat}g</Typography>
              <Typography className={classes.caption} variant="caption">
                Fat
              </Typography>
            </div>
          </div>
          {showView && recipe.ingredients && (
            <IconButton onClick={onViewClick}>
              <RemoveRedEye />
            </IconButton>
          )}
        </div>
      </div>
      <Divider className={classes.divider} />
    </React.Fragment>
  );
}

function MealPlan({ mealPlan, handleBack, updateMealPlan }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showRecipeDialog, setShowRecipeDialog] = useState(false);

  function getTotalMetric(plan, metric) {
    let total = 0;
    for (let i = 0; i < plan.length; i++) {
      total += Number(plan[i][metric]);
    }

    return total;
  }

  async function handleGetNewMealPlan() {
    setLoading(true);
    try {
      const response = await getMealPlan();
      updateMealPlan(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("ERROR: ", e);
    }
  }
  return (
    <React.Fragment>
      {showRecipeDialog && (
        <RecipeDialog
          name={mealPlan[showRecipeDialog - 1].name}
          carbs={mealPlan[showRecipeDialog - 1].carbs}
          fat={mealPlan[showRecipeDialog - 1].fat}
          protein={mealPlan[showRecipeDialog - 1].protein}
          calories={mealPlan[showRecipeDialog - 1].calories}
          ingredients={mealPlan[showRecipeDialog - 1].ingredients}
          handleClose={() => setShowRecipeDialog(false)}
        />
      )}
      <IconButton onClick={handleBack} className={classes.iconButton}>
        <ArrowBack />
      </IconButton>
      <PageWrapper style={{ margin: 20, minHeight: "100vh", height: "auto" }}>
        <Typography variant="h4" className={classes.title}>
          Meal Plan
        </Typography>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            {loading && (
              <div className={classes.spinnerContainer}>
                <CircularProgress size={120} className={classes.spinner} />
              </div>
            )}
            {mealPlan.map((recipe, recipeIdx) => (
              <Recipe
                recipe={recipe}
                showView
                onViewClick={() => setShowRecipeDialog(recipeIdx + 1)}
              />
            ))}
            <Recipe
              recipe={{
                name: "Total Macros",
                carbs: getTotalMetric(mealPlan, "carbs"),
                fat: getTotalMetric(mealPlan, "fat"),
                protein: getTotalMetric(mealPlan, "protein"),
                calories: getTotalMetric(mealPlan, "calories"),
              }}
            />
          </CardContent>
        </Card>
        <Fab
          disabled={loading}
          onClick={handleGetNewMealPlan}
          variant="contained"
          className={classes.getMealPlanButton}
        >
          <Refresh />
        </Fab>
      </PageWrapper>
    </React.Fragment>
  );
}

export default MealPlan;
